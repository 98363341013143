import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppState } from '../../AppContext'
import { changeInfoTela } from '../../layout/actions'
import ModalSaibaMaisDispositivosAutorizados from './ModalSaibaMaisDispositivosAutorizados'
import './deviceManager.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import ModalHabilitarDispositivosAutorizados from './ModalHabilitarDispositivosAutorizados'
import { getDevices } from './actions'
import Collapsible from './Collapsible'
import { isDeviceAuthorizer } from './util'

function DeviceManager() {
  const [state, dispatch] = useAppState()
  const location = useLocation()

  useEffect(
    () =>
      dispatch(
        changeInfoTela({
          title: 'Gerenciar dispositivos',
          breadcrumbs: [
            { link: '/seguranca', label: 'Segurança' },
            { label: 'Gerenciar dispositivos' },
          ],
        })
      ),
    []
  )
  const [devices, setDevices] = useState([])

  const [onlyAuthDevices, setOnlyAuthDevices] = useState(true)
  useEffect(() => dispatch(getDevices(setDevices, setOnlyAuthDevices)), [
    location.pathname,
  ])

  const [openModalSaibaMais, setOpenModalSaibaMais] = useState(false)
  const [openModalComoHabilitar, setOpenComoHabilitar] = useState(false)

  const history = useHistory()

  const pendingDevices = devices?.filter(
    d => !d.authorized && !isDeviceAuthorizer(d)
  )
  const authorizedDevices = devices?.filter(
    d => d.authorized && !isDeviceAuthorizer(d)
  )
  const authorizerDevice = devices?.filter(d => isDeviceAuthorizer(d))

  return (
    <>
      <span className="page-title">
        <div className="back-icon" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        Gerenciar dispositivos
      </span>

      <p style={{ color: '#636363' }}>
        Visualize os dispositivos que acessam sua conta gov.br. Para autorizar,
        utilize o aplicativo gov.br.
      </p>
      <p style={{ color: '#636363', marginTop: 0 }}>
        O acesso por diferentes navegadores, ainda que utilizando o mesmo
        aparelho, será apresentado como um novo dispositivo.
      </p>

      <div className="habilitar-dispositivos">
        <div id="label">
          <img src="/images/dispositivos.svg" />
          <span>
            Habilitar acesso apenas por dispositivos autorizados
            <a
              id="saiba-mais"
              style={{ cursor: 'pointer' }}
              onClick={() => setOpenModalSaibaMais(true)}
            >
              <img src="/images/question-circle-regular.svg" />
            </a>
          </span>
        </div>
        <label className="switch">
          <input
            type="checkbox"
            checked={onlyAuthDevices}
            onClick={() => setOpenComoHabilitar(true)}
            readOnly
          />
          <span className="slider round"></span>
        </label>
      </div>

      <Collapsible
        title="Pendentes"
        devices={pendingDevices.reverse()}
        icon="/images/pendentes.svg"
      />
      <Collapsible
        title="Autorizados"
        devices={authorizedDevices.reverse()}
        icon="/images/autorizados.svg"
      />
      <Collapsible
        title="Dispositivo Autorizador"
        devices={authorizerDevice.reverse()[0]}
        icon="/images/star.svg"
        authorizer={true}
      />

      <ModalSaibaMaisDispositivosAutorizados
        open={openModalSaibaMais}
        setOpen={setOpenModalSaibaMais}
      />
      <ModalHabilitarDispositivosAutorizados
        open={openModalComoHabilitar}
        setOpen={setOpenComoHabilitar}
      />
    </>
  )
}

export default DeviceManager
