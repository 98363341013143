import React, { useState } from 'react'
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'
import { useAppState } from '../../AppContext'
import { removeDevice } from './actions'
import './deviceManager.css'

Modal.setAppElement('#root')

function ModalRemoverDispositivo({ open, setOpen, name, deviceId }) {
  const [state, dispatch] = useAppState()
  const history = useHistory()
  const [removed, setRemoved] = useState(false)

  const close = () => {
    if (removed) history.push('/seguranca/gerenciar-dispositivos')
    setOpen(false)
  }

  const remove = () => dispatch(removeDevice(deviceId, setRemoved))

  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      contentLabel="Nome"
      className="modal modalHailitarDispositivosAutorizados"
    >
      {!removed ? (
        <div>
          <p>Tem certeza que deseja remover o dispositivo {name}?</p>
          <div style={{ textAlign: 'center' }}>
            <button className="action-primary" onClick={remove}>
              Sim
            </button>
            <button className="action-secondary" onClick={close}>
              Não
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p>Dispositivo removido com sucesso!</p>
          <div style={{ textAlign: 'center' }}>
            <button className="action-primary" onClick={close}>
              Fechar
            </button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ModalRemoverDispositivo
