import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

function ModalOQueAcontece({ open, setOpen, authorized }) {
  const close = () => {
    setOpen(false)
  }
  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      contentLabel="Nome"
      className="modal"
    >
      <div>
        {authorized ? (
          <>
            <span className="page-title">
              O que acontece ao remover um dispositivo
            </span>
            <p>
              Ao <strong>remover</strong> o dispositivo, ele perde a autorização
              para acessar sua conta e deixa de constar na Gestão de
              dispositivos.
            </p>
            <p>
              Para evitar um futuro acesso, recomenda-se ativar a opção
              "Habilitar acesso apenas por dispositivos autorizados". Nesse
              caso, você precisará autorizar o dispositivo para permitir o
              acesso.
            </p>
          </>
        ) : (
          <>
            <span className="page-title">
              O que acontece ao autorizar ou remover um dispositivo
            </span>
            <p>
              Ao <strong>autorizar</strong> o dispositivo, ele passa a acessar
              sua conta gov.br sem restrições e você não receberá avisos quando
              isso acontecer.
            </p>
            <p>
              Ao <strong>remover</strong> o dispositivo, ele perde a autorização
              para acessar sua conta e deixa de constar na Gestão de
              dispositivos. Para evitar um futuro acesso, recomenda-se ativar a
              opção "Habilitar acesso apenas por dispositivos autorizados".
              Nesse caso, você precisará autorizar o dispositivo para permitir o
              acesso.
            </p>
          </>
        )}

        <div style={{ textAlign: 'center' }}>
          <button className="action-secondary" onClick={close}>
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalOQueAcontece
