import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

function ModalSaibaMaisDispositivosAutorizados({ open, setOpen }) {
  const close = () => {
    setOpen(false)
  }
  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      contentLabel="Nome"
      className="modal"
    >
      <div>
        <span className="page-title">
          Por que habilitar o acesso apenas por dispositivos autorizados
        </span>
        <p>
          Você garante que somente os computadores, celulares e tablets em que
          confia acessarão sua conta gov.br e, assim, aumenta a segurança da sua
          conta.
        </p>
        <p>
          Após habilitar essa opção, sempre que for acessar um serviço digital
          de um <b>novo dispositivo</b>, você receberá um pedido de confirmação
          no seu aplicativo gov.br.
        </p>
        <p>
          É a sua conta gov.br <b>cada vez mais segura!</b>
        </p>
        <div style={{ textAlign: 'center' }}>
          <button className="action-secondary" onClick={close}>
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalSaibaMaisDispositivosAutorizados
