import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useAppState } from '../AppContext'
import { dropdownMenuAvatar, dropdownMenuServico } from './actions'
import { logoutUser } from '../../commons/logged-user/actions'

function useOutsideAlerter(ref) {
  const [state, dispatch] = useAppState()
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(dropdownMenuAvatar(false))
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dispatch, ref])
}

function Avatar(props) {
  const wrapperRef = useRef(null)

  useOutsideAlerter(wrapperRef)

  const [state, dispatch] = useAppState()

  const dropdown = () => {
    dispatch(dropdownMenuAvatar(!state.layout.avatar))
    dispatch(dropdownMenuServico(false))
  }

  return (
    <div ref={wrapperRef} className="dropdown">
      <button id="btnMenu" onClick={() => dropdown()}>
        <img
          alt="Foto"
          className="avatar-pic"
          src={`${state.logged_user.photoBase64}`}
          onError={e => {
            e.target.src = `${process.env.REACT_APP_URL_ASSETS}/images/user-avatar.png`
          }}
        />
      </button>
      <div className={`dropdown-content ${state.layout.avatar ? 'show' : ''}`}>
        <p>
          Olá,{' '}
          <strong className="caixa-alta">
            {state.logged_user.social_name || state.logged_user.name}
          </strong>
        </p>
        <a className="link-nivel" href={`${process.env.REACT_APP_URL_BFF}`}>
          <p className="caixa-alta">
            A sua conta é nível{' '}
            <strong>
              {state.logged_user.reliabilityLevel === 1 && 'Bronze'}
              {state.logged_user.reliabilityLevel === 2 && 'Prata'}
              {state.logged_user.reliabilityLevel === 3 && 'Ouro'}
            </strong>
          </p>
          {state.logged_user.reliabilityLevel === 1 && (
            <img
              id="imglevel"
              alt="Nível"
              src={`${process.env.REACT_APP_URL_ASSETS}/images/bronze_horizontal.png`}
            />
          )}
          {state.logged_user.reliabilityLevel === 2 && (
            <img
              id="imglevel"
              alt="Nível"
              src={`${process.env.REACT_APP_URL_ASSETS}/images/silver_horizontal.png`}
            />
          )}
          {state.logged_user.reliabilityLevel === 3 && (
            <img
              id="imglevel"
              alt="Nível"
              src={`${process.env.REACT_APP_URL_ASSETS}/images/gold_horizontal.png`}
            />
          )}
        </a>

        <span>Minha conta</span>
        <hr />

        <ul className="avatarmenu">
          <li>
            <Link
              to="/alteracao_cadastro"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Dados Pessoais
            </Link>
          </li>
          <li>
            <Link
              to="/seguranca"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Segurança da Conta
            </Link>
          </li>
          <li>
            <Link
              to="/privacidade"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Privacidade
            </Link>
          </li>
          {state.logged_user.cnpj && (
            <li>
              <Link
                to="/empresas"
                onClick={() => dispatch(dropdownMenuAvatar(false))}
              >
                Vincular Empresas via e-CNPJ
              </Link>
            </li>
          )}
        </ul>

        <span>Serviços</span>
        <hr />

        <ul className="avatarmenu">
          <li>
            <a
              href={`${process.env.REACT_APP_PORTAL_LOGADO_URL}/carteira`}
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Carteira de documentos
            </a>
          </li>
          <li>
            <a
              href={`${process.env.REACT_APP_PORTAL_LOGADO_URL}/certidoes`}
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Baixar certidões
            </a>
          </li>
          <li>
            <a
              href="https://assinador.iti.br/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Assinar documentos digitalmente
            </a>
          </li>
          {/* <li>
            <a
              href={`${process.env.REACT_APP_PORTAL_LOGADO_URL}`}
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Serviços solicitados
            </a>
          </li> */}
          <li>
            <a
              href={`${process.env.REACT_APP_PORTAL_LOGADO_URL}/notificacoes`}
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Notificações
            </a>
          </li>
          <li>
            <a
              href="https://www.gov.br/governodigital/pt-br/conta-gov-br/ajuda-da-conta-gov.br"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => dispatch(dropdownMenuAvatar(false))}
            >
              Ajuda da conta gov.br
            </a>
          </li>
        </ul>

        <button
          className="btn-secondary"
          id="btnLogout"
          onClick={() => dispatch(logoutUser())}
        >
          Sair da conta
        </button>
      </div>
    </div>
  )
}

export default Avatar
