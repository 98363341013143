import React from 'react'
import Modal from 'react-modal'
import './deviceManager.css'

Modal.setAppElement('#root')

function ModalHabilitarDispositivosAutorizados({ open, setOpen }) {
  const close = () => {
    setOpen(false)
  }
  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      contentLabel="Nome"
      className="modal modalHailitarDispositivosAutorizados"
    >
      <div>
        <p>
          Para habilitar ou desabilitar a opção{' '}
          <em>“acesso apenas por dispositivos autorizados”</em>, utilize o{' '}
          <strong>aplicativo gov.br</strong>.
        </p>

        <div style={{ textAlign: 'center' }}>
          <button className="action-secondary" onClick={close}>
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalHabilitarDispositivosAutorizados
