import React, { useState } from 'react'
import Device from './Device'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalDispositivoAutorizador from './ModalDispositivoAutorizador'

function Collapsible({ title, devices = [], icon, authorizer }) {
  const [collapsible, setCollapsible] = useState(
    title === 'Pendentes' ? true : false
  )
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <div className="collapsible" onClick={() => setCollapsible(!collapsible)}>
        <div>
          <img src={icon} />
          {title}{' '}
          {authorizer ? (
            <img
              src="/images/question-circle-regular.svg"
              onClick={() => setOpenModal(true)}
            />
          ) : (
            <span>({devices.length})</span>
          )}
        </div>
        <FontAwesomeIcon icon={collapsible ? faChevronUp : faChevronDown} />
      </div>
      <div
        className="content"
        style={collapsible ? { display: 'block' } : { display: 'none' }}
      >
        {authorizer
          ? devices &&
            !Array.isArray(devices) && (
              <Device key={devices.id} device={devices} />
            )
          : devices.map(d => <Device key={d.id} device={d} />)}
      </div>

      <ModalDispositivoAutorizador open={openModal} setOpen={setOpenModal} />
    </>
  )
}

export default Collapsible
