import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

function ModalDispositivoAutorizador({ open, setOpen }) {
  const close = () => {
    setOpen(false)
  }
  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      contentLabel="Nome"
      className="modal"
    >
      <div>
        <span className="page-title">O que é um dispositivo autorizador</span>
        <p>
          Dispositivo autorizador é o celular que você usa para acessar o
          aplicativo govbr. Ele é o único dispositivo com o qual é possível
          autorizar o acesso de outros dispositivos.
        </p>
        <div style={{ textAlign: 'center' }}>
          <button className="action-secondary" onClick={close}>
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDispositivoAutorizador
